exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/About.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/Blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-complaint-page-jsx": () => import("./../../../src/templates/ComplaintPage.jsx" /* webpackChunkName: "component---src-templates-complaint-page-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-faq-jsx": () => import("./../../../src/templates/Faq.jsx" /* webpackChunkName: "component---src-templates-faq-jsx" */),
  "component---src-templates-homepage-jsx": () => import("./../../../src/templates/Homepage.jsx" /* webpackChunkName: "component---src-templates-homepage-jsx" */),
  "component---src-templates-loan-calculation-jsx": () => import("./../../../src/templates/LoanCalculation.jsx" /* webpackChunkName: "component---src-templates-loan-calculation-jsx" */),
  "component---src-templates-loan-page-jsx": () => import("./../../../src/templates/LoanPage.jsx" /* webpackChunkName: "component---src-templates-loan-page-jsx" */),
  "component---src-templates-simple-page-jsx": () => import("./../../../src/templates/SimplePage.jsx" /* webpackChunkName: "component---src-templates-simple-page-jsx" */)
}

