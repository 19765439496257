/* Gatsby Browser API
 * -------------------
 * The file gatsby-browser.js lets you respond to Gatsby-specific events within
 * the browser, and wrap your page components in additional global components.
 * The Gatsby Browser API gives you many options for interacting with the
 * client-side of Gatsby.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import React from "react";
import { ThemeProvider } from "./src/context/ThemeContext";

export const wrapPageElement = ({ element, props }) => {
  return <ThemeProvider pageData={props.data}>{element}</ThemeProvider>;
};

export const onClientEntry = () => {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.size > 0) {
    window.sessionStorage.setItem("utm_href", window.location.href);
  }
};
