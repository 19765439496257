module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","lang":"el","name":"IMS - Individual Mortgage Solutions","short_name":"Individual Mortgage Solutions","description":"The company IMS, staffed by qualified mortgage brokers with extensive experience in the banking sector, is the largest partner of Greek Banks in mediation for the issuance of housing loans.","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"./src/images/fav.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f75931f9daf3791dc46fc801caf2bd1f"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
